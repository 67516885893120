import avtar from "../../assets/images/default-avatar.jpg";
const initialState = {
  profileImage:localStorage.getItem("profileImage")??avtar,
  otherService: "",
  setOtherServiceOn: false,
  loginUserRoleType:undefined,
  clientId:undefined,
  tenantId:undefined,
  role:""
};

const profileReducer = (state = initialState, action) => {
    if (action.type==="UPDATE_PROFILE_IMAGE") {
        return {
          ...state,
          profileImage: action.payload,
        }
      }
      if (action.type==="USER_ROLE") {
        return {
          ...state,
          role: action.payload,
        }
      }
      if (action.type==="USER_ID") {
        return {
          ...state,
          id: action.payload,
        }
      }
    if (action.type === "OTHER_SERVICE") {
        return {
          ...state,
          otherService: action.payload,
        };
    }
    if (action.type === "SET_OTHER_SERVICE_ON") {
      return {
        ...state,
        setOtherServiceOn: action.payload,
      };
    }
    if (action.type === "LOGIN_USER_ROLE_TYPE") {     
      return {
        ...state,
        loginUserRoleType: action.payload,
      };
    }
    if (action.type === "CLIENT_ID") {     
      return {
        ...state,
        clientId: action.payload,
      };
    }
    if (action.type === "TENANT_ID") {     
      return {
        ...state,
        tenantId: action.payload,
      };
    }
    if (action.type === "LOGGEDIN_USER_ROLE") {     
      return {
        ...state,
        role: action.payload,
      };
    }    
      else{return state;}
  };
  
  export default profileReducer;
  






