export const updateProfileImage = (imageUrl) => ({
    type: 'UPDATE_PROFILE_IMAGE',
    payload: imageUrl,
  });

  export const updateUserRole = (role) => ({
    type: 'USER_ROLE',
    payload: role,
  });

  export const updateUserId = (id) => ({
    type: 'USER_ID',
    payload: id,
  });


export const otherService = (email) => ({
    type: 'OTHER_SERVICE',
    payload: email,
});

export const setOtherServiceOn = (bool) => ({
  type: 'SET_OTHER_SERVICE_ON',
  payload: bool,
});

export const loginUserRoleType = (role) => ({
  type: 'LOGIN_USER_ROLE_TYPE',
  payload: role,
});

export const clientId = (id) => ({
  type: 'CLIENT_ID',
  payload: id,
});

export const tenantId = (id) => ({
  type: 'TENANT_ID',
  payload: id,
});

export const loggedInUserRole = (role) => ({
  type: 'LOGGEDIN_USER_ROLE',
  payload: role,
});


  

 